@import 'assets/styles/variables';

.container-userDetail {
  padding: 0rem 5rem;
  margin: 0px;
  .container-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    .spinnerUser {
      width: 40px;
      height: 40px;
    }
  }
  .icon {
    width: 50px;
    height: 44px;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: black;
  }
  .containerPicture {
    display: flex;
    justify-content: center;
    align-items: center;
    .pictureUser {
      border-radius: 50%;
      border: 5px solid #fff;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      height: 180px;
      width: 180px; 
    }
  }
  .containerInfo {
    .name2 {
      float: left;
      color: $orange;
      font: normal normal bold 30px/36px Lato;
      margin: 1rem 0rem;
    }
    .senioritie-form {
      .button-senioritie { 
        width: 6rem;
        .btn-save {
          background-color: #F77B2C;
          border: none;
          border-radius: 5px;
          color: white;
          font-weight: bold;
        }
        .btn-cancel {
          background-color: gray;
          border: none;
          border-radius: 5px;
          color: white;
          font-weight: bold;
        }
      } 
    } 
    .senioritie-add {
      border: 1px solid #cccccc;
      border-radius: 5px;
      color: black;
      padding: 10px;
      margin: 10px 0px;
      cursor: pointer;
    }
    .position {
      float: left;
      color: #535353;
      font: normal normal bold 24px/29px Lato;
    }
    .email {
      float: left;
      color: #535353;
      font: normal normal 20px Lato;
      margin: 0.5rem 0rem;
    }
    .description {
      float: left;
      color: #535353;
      font: normal normal normal 14px/20px Lato;
      margin: 0.5rem 0rem;
      overflow-wrap: break-word;
    }
  }  
  .featureContainer {
    padding: 2rem;
    margin: 1rem 0rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    height: auto;
    .titles {
      font: normal normal bold 20px/24px Lato;
      color: #F77B2C;
      width: 100%;
      .statusMate {
        font: normal normal bold 14px Lato !important;
        color: #000;
      }
    }
    .items-row {
      .skill-badge {
        font-size: 1rem;
        background: #dad8d8 0% 0% no-repeat padding-box;
        color: black;
        font-weight: bold;
      }
    }
  }
}
.container-references {
  padding: 0rem 5rem;
  margin: 0px;
  .title {
    text-align: center;
    color: #535353;
    font: normal normal bold 20px Lato;
    .reference-user-form {
      width: auto;
      justify-content: center;
      margin: auto;
      .description {
        width: 100%;
        float: left;
        color: #535353;
        font: normal normal normal 14px/20px Lato;
        margin: 0.5rem 0rem;
        text-align: justify;
        .description-input {
          width: 100%;
        }
      }
      textarea {
        width: 100%;
      }
    }
  }
  .container-subtitle {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconLeft {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-picture-thumb {
        margin-left: 30px;
      }
    }
    .iconRight {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-picture-thumb {
        margin-left: 30px;
      }
    }
  }
  .subtitles {
    color: #000;
    font: normal normal bold 17px Lato;
    margin-bottom: 10px;
  }
  .card-user-references {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    padding: 0px;
    background-color: white;
    border: 1px solid #cccccc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    label {
      font-weight: bold;
      font-size: 1rem;
    }
    .form-control:disabled, .form-control[readonly] {
      background-color: white;
    }
    .status-picture-delete {
      cursor: pointer;
      float: right;
      border: none;
      input {
        display: none;
      }
    }
    .title{
      max-width: 60%;
    }
    .url {
      margin-top: 1rem;
      max-width: 60%;
    }
  }
}
.save-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  button {
    width: 10rem;
    font-size: 1.2rem;
  }
  button:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
#simple-modal-title {
  text-align: center;
  font-weight: bold;
  color: $orange;
  margin-bottom: 20px;
}
#simple-modal-description {
  text-align: center;
  margin: 0px;
  font-size: 1.2rem;
}

@media (max-width: 1100px) and (min-width: 769px) {
  .container-userDetail {
    padding: 0rem 3rem;
    .containerPicture {
      .pictureUser {
        border-radius: 50%;
        border: 5px solid #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        height: 140px;
        width: 140px; 
      }
    }
    .containerInfo {
      .name2 {
        font: normal normal bold 27px Lato;
      }
      .position {
        font: normal normal bold 20px Lato;
      }
      .description {
        font: normal normal normal 13px Lato;
      }
    }  
    .featureContainer {
      .titles {
        font: normal normal bold 18px Lato;
      }
    }
  }
  .container-references {
    padding: 0rem 3rem;
    margin: 0px;
  }
  .save-submit {
    button {
      width: 7rem;
    }
  }
}

@media (max-width: 768px) {
  .container-userDetail {
    padding: 0rem 3rem;
    margin: auto;
    .containerPicture {
      margin-left: 10px;
      width: 100%;
      .pictureUser {
        border-radius: 50%;
        border: 5px solid #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        height: 140px;
        width: 140px; 
      }
    }
    .containerInfo {
      margin-left: 10px;
      .name2 {
        font: normal normal bold 27px Lato;
        text-align: center;
      }
      .senioritie-text {
        h5 {
          text-align: center;
        }
      }
      .position {
        font: normal normal bold 20px Lato;
        text-align: center;
      }
      .email {
        text-align: center;
      }
      .description {
        font: normal normal normal 13px Lato;
        text-align: center;
      }
    }  
    .featureContainer {
      .titles {
        font: normal normal bold 18px Lato;
      }
    }
  }
  .container-references {
    padding: 0rem 3rem;
    margin: 0px;
    .cardReferenceLeft {
      width: 90%;
    }
    .cardReferenceRight {
      width: 9%;
    }
  }
}
.label-introduction {
  text-align: right;
  display: block;
  color: gray;
}
.container-userDetail .featureContainer .items-row .experienceDescription {
  overflow-wrap: anywhere;
}