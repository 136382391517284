@import 'assets/styles/variables';
.openUrl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  color: black;
  .textOpen {
    color: black;
    text-decoration: underline;
  }
  .iconOpen {
    width: 18px;
  }
}
.preview {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 2rem;
  .curriculum-preview {
    height: 650px;
  }
  position: relative;
}

.spinner { 
  display: flex;
  justify-content: center;
  margin: auto;
}
.spinner-border {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: 3px;
}