@import 'assets/styles/variables';
.containerGeneral {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .spinner {
    width: 40px;
    height: 40px;
  }
  h5 {
    text-align: center;
  }

  .user-name {
    height: 70px;
    overflow: hidden;
    max-width: 160px;
  }

  .containerCards {
    margin: 0.8rem;
    width: 375px;
    .containerCard {
      border-radius: 20px;
      .oneCard {
        margin: 0px;
        padding: 16px;
        background: linear-gradient(#494e55, #272E3C);
        border: none;
        .profile-picture-edit{
          float: left;
          margin-top: -9px;
          img {
            height: 20px;
          }
        }
        .profile-picture-pdf{
          float: right;
          padding-top: 7px;
          img {
            height: 20px;
          }
        }
        .profile-picture-thumb {
          padding: 0;
          margin: 0;
          width: 8px;
        }
        .pictureUser {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          border: 5px solid white;
          display: block;
          margin: auto;
          margin: 0px;
        }
        .name {
          color: $orange;
          font: normal normal bold 22px Lato;
          margin: 0.5rem 0rem;
          cursor: pointer;
          max-width: 180px;
          min-height: 58px;
          text-transform: capitalize;
        
        }
        .seniority {
          color: white;
          font: normal normal normal 16px/19px Lato;
          margin: 0.5rem 0rem;
        }
        .languages {
          color: white;
          font: normal normal normal 14px Lato;
          margin: 0.3rem 0rem;
        }
        .skill-badge {
          background-color: white;
          color: #535353;
          font-size: 13px;
          text-align: center;
        }
        .language {
          height: 30px;
        }
        .pictureFlag {
          margin-left: 10px;
          margin-right: 5px;
          width: 25px;
        }
        .languageLevel {
          font-size: 10px;
          text-decoration: none;
          color: white;
        }
      }
      .statusUser {
        height: 40px;
        text-align: center;
        padding: 7px;
        .statusName {
          color: white;
          font: normal normal 900 14px/17px Lato;
          text-align: center;
          text-transform: uppercase;
        }
      }
      .new-user {
        background-color: black;
      }
      .candidate {
        background-color: #6348D5;
      }
      .possible-candidate {
        background-color: #78C4B1;
      }
      .accepted {
        background-color: #00A075;
      }
      .to-interview {
        background-color: orange;
      }
      .rejected {
        background-color: red;
      }
      .dont-accept {
        background-color: red;
      }
      .mate {
        background-color: $orange;
      }
      .ex-mate {
        background-color: grey;
      }
    }
    .MuiPaper-root.MuiCard-root.containerCard.MuiPaper-elevation1.MuiPaper-rouded  {
      margin: 0px !important;
    }
    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
    .MuiCardContent-root {
      border: none;
    }
    .content-up {
      .badgeUp {
        margin-left: -20px !important;
        color: white;
        width: 25px;
        font-size: medium;
      }
    }
    .content-down {
      margin-top: 10px;
      .badgeDown {
        margin-left: -20px !important;
        color: white;
        width: 25px;
        font-size: medium;
      }
    }
  }
}
.card-user-form {
  .spinner {
    width: 40px;
    height: 40px;
  }
  .titleFilter {
    font: normal normal bold 20px/24px Lato;
    color: #F77B2C;
    text-align: center;
  }
  .picture-search-filter{
    color: black;
    cursor: pointer;
    margin: 0px 8px;
    img {
      height: 23px;
    }
  }
  .picture-delete-filter{
    color: black;
    cursor: pointer;
    margin: 0px 8px;
    img {
      height: 23px;
    }
  }
  .iconsFilter  {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
}
.MuiBadge-colorPrimary {
  background-color: transparent !important;
  color: $orange !important;
  font-size: 1rem !important;
}

@media (max-width: 1400px) and (min-width: 768px){
  .containerGeneral {
    .containerCards {
      width: 310px;
      .oneCard {
        .leftCard {
          width: 35%;
        }
        .centerCard {
          width: 52%;
        }
        .rightCard {
          width: 10%;
          padding: 1px !important;
        }
      }
      .content-up {
        .badgeUp {
          margin-left: 5px !important;
        }
      }
      .content-down {
          margin-top: 10px;
        .badgeDown {
          margin-left: 5px !important;
        }
      }
    } 
  }
}


@media (max-width: 767px){
  .containerGeneral {
    .containerCards {
      width: 340px;
      .containerCard {
        .oneCard {
          margin: 0px;
          padding: 16px 16px 6px 16px;
          .profile-picture-edit{
            img {
              height: 20px;
            }
          }
          .profile-picture-pdf{
            img {
              height: 20px;
            }
          }
          .leftCard {
            width: 35%;
          }
          .centerCard {
            width: 55%;
          }
          .rightCard {
            width: 8%;
          }
          .name {
            font: normal normal bold 20px Lato;
          }
          .seniority {
            font: normal normal normal 14px Lato;
          }
          .languages {
            margin: 0.3rem 0rem;
          }
        }
      }
      .statusUser {
        .statusLeft {
          width: 18%;
        }
        .statusRight {
          width: 8%;
        }
      }
    }
  }
  .card-user-form {
    width: 100%;
    .titleFilter {
      font: normal normal bold 20px/24px Lato;
      color: #F77B2C;
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 10px 0px;
    }
    .filters {
      margin: 6px 0px;
    }
    .picture-search-filter{
      color: black;
      cursor: pointer;
      margin: 0px 8px;
      img {
        height: 23px;
      }
    }
    .picture-delete-filter{
      color: black;
      cursor: pointer;
      margin: 0px 8px;
      img {
        height: 23px;
      }
    }
    .iconsFilter  {
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 10px 0px;
    }
  }
  .MuiIconButton-label {
    margin-left: -30px;
  }
}
.card-user-form Input, .card-user-form Select {
  margin: 0 !important;
}
span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-61.MuiCheckbox-root.MuiCheckbox-colorSecondary.MuiIconButton-colorSecondary {
  padding: 0 !important;
}
.PrivateSwitchBase-root-1 {
  padding: 0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #F77B2C;
}
.MuiIconButton-colorSecondary {
  color: #F77B2C;
}