.user-progress {
  width: 100%;
  padding: 0;
  .progress-bar {
    background-color: #fd7e14;
  }
}
.user-progress-alert {
  width: 100%;
  padding: 0;
  .progress-bar {
    background-color: #fd7e14;
  }  
}
.user-progress-success {
  width: 100%;
  padding: 0;
  .progress-bar {
    background-color: green;
  }  
}
.alert{
  float: right;
  width: 30rem;
}
.progress-bar.progress-bar-striped {
  font-weight: bold;
}