@import 'assets/styles/variables';

.tabs-container {
  flex-direction: row;
  position: sticky;
  top: 0;
  width: 100%;
  margin: 0;
  z-index: 100;
  border: none;
  .container-fluid {
    width: 100%;
    background-color: transparent;
    border: transparent;
    .navbar-toggler {
      margin: 8px;
      border: 1px solid $color-grey-20;
      border-radius: 5px;
    }
    .nav-item {
      margin: 8px 50px;
      max-width: 150px;
      .tab-link {
        font-weight: bold;
        background-color: transparent;
        border: transparent;
      }
      .tab-link:hover {
        color: $orange;
        font-size: 1rem;
      }
      .tab-link.selected:hover {
        color: $orange;
      }
      .tab-link:focus {
        box-shadow: none;
      }
      .selected {
        color: $orange;
      }
    }
  }
  .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 990px) and (max-width: 1550px){
  .tabs-container {
    .container-fluid {
      .nav-item {
        margin: 8px 20px;
        max-width: 150px;
        .tab-link {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 986px){
  .tabs-container {
    .container-fluid {
      .nav-item {
        margin: 14px !important;
        max-width: 150px;
      }
    }
  }
}