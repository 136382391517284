@import 'assets/styles/variables';

.container-userDetail {
  padding: 0rem 5rem;
  margin: 0px;

  .hide-button {
    display: none;
  }

  .editUser-icon {
    display: flex;
    justify-content: right;
    cursor: pointer;
  }

  .container-spinner {
    display: flex;
    align-items: center;
    justify-content: center;

    .spinnerUser {
      width: 40px;
      height: 40px;
    }
  }

  .icon {
    width: 50px;
    height: 44px;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: black;
  }

  .containerPicture {
    display: flex;
    justify-content: center;
    align-items: center;

    .pictureUser {
      border-radius: 50%;
      border: 5px solid #fff;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      height: 180px;
      width: 180px;
    }
  }

  .containerInfo {
    .container-pdf-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-pdf {
        img {
          height: 30px;
        }
      }
    }

    .name2 {
      float: left;
      color: $orange;
      font: normal normal bold 30px/36px Lato;
      margin: 1rem 0rem;
    }

    .senioritie-form {
      .button-senioritie {
        width: 6rem;
        height: 2.3rem;

        .btn-save {
          background-color: #F77B2C;
          border: none;
          border-radius: 5px;
          color: white;
          font-weight: bold;
        }

        .btn-cancel {
          background-color: gray;
          border: none;
          border-radius: 5px;
          color: white;
          font-weight: bold;
        }
      }
    }

    .senioritie-add {
      border: 1px solid #cccccc;
      border-radius: 5px;
      color: black;
      padding: 10px;
      margin: 10px 0px;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .position {
      float: left;
      color: #535353;
      font: normal normal bold 24px/29px Lato;
    }

    .email {
      float: left;
      color: #535353;
      font: normal normal 20px Lato;
      margin: 0.5rem 0rem;
    }

    .description {
      float: left;
      color: #535353;
      font: normal normal normal 14px/20px Lato;
      margin: 0.5rem 0rem;
      text-align: justify;
    }
  }

  .featureContainer {
    padding: 2rem;
    margin: 1rem 0rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    height: auto;

    .titles {
      font: normal normal bold 20px/24px Lato;
      color: #F77B2C;
      width: 100%;
      margin-bottom: 10px;

      .statusMate {
        font: normal normal bold 14px Lato !important;
        color: #000;
      }
    }

    .items-row {
      span {
        margin: 0px;
        font-weight: normal;

        p {
          margin: 0px;
        }

        label {
          font-weight: bold;

          a {
            color: black;
          }
        }

        .infoEducations {
          margin-right: 3px;
        }
      }

      .textOpen {
        a {
          text-decoration: none;
          padding: 0px;
        }

        .name {
          color: black;
          font-weight: bold;
        }
      }

      .iconOpen {
        width: 18px;
      }

      .skill-badge {
        font-size: 1rem;
        background: #dad8d8 0% 0% no-repeat padding-box;
        color: black;
      }

      .experienceDescription {
        text-align: justify;
        font-weight: normal;
      }

      .references {
        margin-left: 20px;

        label {
          margin-top: 5px;
          cursor: pointer;
          color: black;
        }

        label.description {
          font-weight: normal;
          margin-left: 4px;
        }

        .descriptionReference {
          color: black;
          margin-left: 5px;
          font-weight: normal;
        }
      }
    }
  }
}

.input-date-status {
  .form-control[readonly] {
    background-color: white;
  }
}

.container-comments {
  padding: 0rem 5rem;
  margin: 0px;
  margin-top: 40px;

  .userAdmin {
    text-align: center;
  }

  .title {
    text-align: center;
    color: #535353;
    font: normal normal bold 20px Lato;

    .comment-user-form {
      width: auto;
      justify-content: center;
      margin: auto;

      .notEdit {

        .form-control:disabled,
        .form-control[readonly] {
          background-color: white !important;
        }
      }

      .form-body {
        .text-form {
          label {
            font-weight: bold;
          }

          input {
            width: 100%;
            height: 45px;
          }

          .statusChange,
          .commentContent {
            width: 100%;
          }

          .commentContent {
            max-width: 800px;

            textarea {
              height: 5rem;
              max-height: 9rem;
              text-align: left;
              display: block;
            }
          }
        }
      }
    }

  }

  .ck-editor__editable_inline {
    min-height: 250px;
    color: #000;
  }

  .card-user-status {
    background-color: white;
    border: 1px solid #cccccc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    justify-content: center;
    overflow-wrap: break-word;
    margin: 0;
    margin-top: 20px;
    padding: 0px;
    width: 100%;

    label {
      font-weight: bold;
      font-size: medium;
    }

    .form-control:disabled,
    .form-control[readonly] {
      background-color: white;
    }

    .status-picture-delete {
      cursor: pointer;
      float: right;
      border: none;

      input {
        display: none;
      }
    }

    .title {
      max-width: 60%;
    }

    .url {
      margin-top: 1rem;
      max-width: 60%;
    }
  }
}

.hidden-form {
  display: none;
}

.add-comment {
  color: #fff;
  background: linear-gradient(#494e55, #272E3C);
  border: 1px solid #000;
  box-shadow: -5px 10px #888888;
  border-radius: 1rem;
  bottom: auto;
  position: absolute;
  left: 0;
  margin: auto;
  overflow: hidden;
  padding: 1rem;
  right: 0;
  top: auto;
  width: 90%;
  z-index: 100;
}

.more-text {
  border: 1px solid #000;
  box-shadow: -5px 10px #272E3C;
  border-radius: 1rem;
  height: auto;
  width: 60rem;
  color: #fff;
  background-color: #888888;
  padding: 1rem;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 2rem;
  right: 0;

  .btn-close {
    background-color: #fff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    width: 100%;
  }
}

.deleteBox {
  display: none;
}

.btn-comment {
  background-color: #F77B2C;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  width: 100%;

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.btn-more {
  background-color: #F77B2C;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  width: 100%;
}

.save-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  button {
    width: 10rem;
    font-size: 1.2rem;
  }

  button:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

@media (max-width: 1100px) and (min-width: 769px) {
  .container-userDetail {
    padding: 0rem 3rem;

    .containerPicture {
      .pictureUser {
        border-radius: 50%;
        border: 5px solid #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        height: 140px;
        width: 140px;
      }
    }

    .containerInfo {
      .name2 {
        font: normal normal bold 27px Lato;
      }

      .position {
        font: normal normal bold 20px Lato;
      }

      .description {
        font: normal normal normal 13px Lato;
      }
    }

    .featureContainer {
      .titles {
        font: normal normal bold 18px Lato;

        .description {
          font: normal normal bold 18px Lato;
        }
      }
    }
  }

  .container-comments {
    padding: 0rem 3rem;

    .title {
      text-align: center;
      color: #535353;
      font: normal normal bold 20px Lato;
    }
  }

  .save-submit {
    button {
      width: 7rem;
    }
  }
}

@media (max-width: 768px) {
  .container-userDetail {
    padding: 0rem;
    margin-top: 1rem;

    .container-personal-data {
      width: 100%;
      margin: 0;
      padding: 0;

      .containerPicture {
        margin-left: 10px;
        width: 100%;

        .pictureUser {
          border-radius: 50%;
          border: 5px solid #fff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          height: 140px;
          width: 140px;
        }
      }

      .containerInfo {
        margin-left: 10px;

        .name2 {
          font: normal normal bold 27px Lato;
          text-align: center;
          margin-bottom: 6px;
        }

        .senioritie-form {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .senioritieChange {
            width: 65%;
          }

          .button-senioritie {
            width: 29%;
          }
        }

        .container-senioritie {
          display: flex;
          justify-content: center;
          align-items: center;

          .senioritie-add {
            margin: auto;
          }
        }

        .senioritie {
          width: 80%;
          margin: auto;

          .senioritie-text {
            width: 70%;

            h5 {
              text-align: right;
            }
          }

          .senioritie-edit {
            text-align: left;
            width: 30%;
          }
        }

        .position {
          font: normal normal bold 20px Lato;
          text-align: center;
          margin-top: 10px;
        }

        .email {
          text-align: center;
        }

        .description {
          font: normal normal normal 13px Lato;
          text-align: justify;
        }

        .container-pdf-icon {
          width: 100%;
        }
      }

      .featureContainer {
        .titles {
          font: normal normal bold 18px Lato;
        }
      }
    }
  }

  .container-comments {
    padding: 0rem 3rem;
    margin: 0px;

    .card-user-status {
      .cardStatusLeft {
        width: 90%;
      }

      .cardStatusRight {
        width: 10%;
      }
    }
  }

  .MuiFormControlLabel-root {
    margin: 0px !important;
  }
}

.label-introduction {
  text-align: right;
  display: block;
  color: gray;
}
