@import 'assets/styles/variables';

.personal-data-form {
  width: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
  .form-body {
    .profile-picture {
      font-weight: bold;
      width: auto;
      #form-label-profile {
        margin-top: 0.3rem;
      }
      img {
        margin-top: 0px;
      }
      .profile-picture-delete {
        cursor: pointer;
        float: right;
        border: none;
        input {
          display: none;
        }
      }
      .profile-picture-update-icon {
        float: right;
        cursor: pointer;
        padding-right: 0.7rem;
        input[type='file'] {
          display: none;
        }
      }
      .profile-picture-update {
        float: right;
        cursor: default;
        padding-right: 0.7rem;
        input[type='file'] {
          display: none;
        }
      }
      .canvas {
        display: none;
      } 
      .react-crop {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
        .ReactCrop {
          width: auto;
          height: auto;
          max-width: 300px;
          max-height: 600px;
          border: 5px solid #fff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          .ReactCrop__crop-selection {
            border-radius: 50%;
          }
        }
      } 
      .buttons-photo {
        margin-right: 12px;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        .savePhoto, .cancelPhoto {
          width: auto !important;
          padding: 8px;
        }
        button {
          font-size: 1.2rem;
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }
        button:hover {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      canvas {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-top: 10px;
      } 
      .img {
        display: block;
        margin: auto;
        width: 100%;
        text-align: center;
        padding-right: 0px;
        .profile-img {
          border-radius: 50%;
          border: 5px solid #fff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          height: 200px;
          width: 200px; 
          margin: 20px 0px;
        }
      }
    }
    .inputCV {
      width: 95%;
      input {
        display: none;
      }
      .textOpen {
        width: 200px;
        color: black;
        text-decoration: underline;
        margin-bottom: 10px;
      }
      .iconOpen {
        width: 18px;
        margin-left: -20px;
      }
    }
    .inputCV2 {
      width: 100%;
    }
    .deleteCV {
      justify-content: center;
      align-items: center;
      display: flex;
      width: 5%;
      .profile-cv-delete {
        cursor: pointer;
        float: right;
        border: none;
        input {
          display: none;
        }
      }
    }
    .text-form {
      label {
        font-weight: bold;
      }
      input {
        width: 100%;
        height: 45px;
      }
      .first-name,
      .last-name,
      .country,
      .city,
      .email,
      .phone {
        margin: 0px;
      }
      .phone {
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
        input[type=number] { -moz-appearance:textfield; }
      }      
      .introduction, 
      .curriculumFIle, 
      .skills {
        margin: 0px;
        textarea {
          height: 5rem;
          max-height: 9rem;
          text-align: left;
          display: block;
        }
        .label-introduction {
          text-align: right;
          display: block;
          color: gray;
        }
      }
      .skills-row {
        max-width: 1000px;
        .skill-badge {
          font-size: 1rem;
          background: #dad8d8 0% 0% no-repeat padding-box;
          color: black;
        }
        .MuiSvgIcon-root {
          width: 0.8rem;
          margin-top: -10px;
          margin-left: 5px;
        }
        .MuiIconButton-root {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
      .curriculum-preview {
        height: 500px;
      }
    }
    .save-submit {
      margin-right: 12px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      button.save {
        width: 12rem;
      }
      button.continue {
        width: auto !important;
      }
      button {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
      button:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
}
.close-icon {
  float: right;
  width: 100%;
  margin-right: -25px;
  margin-top: -10px;
  cursor: pointer;
}
#transition-modal-description {
  margin: 20px;
  text-align: center;
  color: $orange;
  font-weight: bold;
  font-size: 1.2rem;
}

@media (max-width: 1100px) and (min-width: 769px) {
  .personal-data-form {
    .form-body {
      #formRight {
        width: 100%;
        padding: 10px;
      }
      .profile-picture {
        .img {
          display: block;
          margin: auto;
          .profile-img {
            height: 200px;
            width: 200px; 
            margin-top: 30px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .personal-data-form {
    .form-body {
      #formRight {
        width: 100%;
        padding: 30px;
      }
      .profile-picture {
        .img {
          display: block;
          margin: auto;
          .profile-img {
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            height: 200px;
            width: 200px;
            display: flex;
            justify-content: center;
            margin: auto; 
            margin-top:30px;
            margin-bottom:30px;
          }
        }
      }
    }
  }
  .skills-row {
    max-width: 1000px;
    .skill-badge {
      font-size: 1rem;
      background: #dad8d8 0% 0% no-repeat padding-box;
      color: black;
    }
    .MuiSvgIcon-root {
      width: 0.8rem;
      margin-top: -10px;
      margin-left: 5px;
      display: inline-block !important;
    }
    .MuiIconButton-root {
      padding: 0px !important;
      margin: 0px !important;
      display: inline-block !important;
    }
  }

  .MuiButtonBase-root {
    display: inline-block !important;
    background-color: blue;
  }
  .close-icon {
    float: right;
    width: 100%;
    margin-right: -25px;
    margin-top: -10px;
    cursor: pointer;
  }
  #transition-modal-description {
    margin: 20px;
    text-align: center;
    color: $orange;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.error {
  color: $danger;
  font-weight: bold;
}