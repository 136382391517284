@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'variables';

body {
  background-color: $color-white-10;
  margin: 0;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
}

.button-orange {
  width: 400px;
  height: 50px;
  border-style: solid;
  border-radius: 5px;
  border-color: $color-orange;
  border-width: 2px;
  background-color: $color-orange;
  color: $color-white;
  font-size: 1.14rem;
  font-weight: bold;
}


