@import 'assets/styles/variables';

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: $input-border;
  border-radius: 4px;
}
.react-autosuggest__input--focused {
  outline: none;
  border: $focused-input-border;
  box-shadow: 0 0 0 0.17rem $orange;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: $input-border;
  background-color: #fff;
  font-weight: 300;
  border-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}