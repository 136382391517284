@import 'assets/styles/variables';

.error {
  color: $danger;
  font-weight: bold;
}
.form-control:focus {
  box-shadow: 0 0 0 0.17rem $orange;
}
.form-control-curriculum:focus {
  box-shadow: 0 0 0 0.17rem transparent;
}