@import 'assets/styles/variables';
@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.login {
  display: flex;
  justify-content: center;
  padding-top: 15.09vh;
  .login-form {
    text-align: center;
    .logo {
     width: 70%;
    }
    .title {
      color: #535353;
      font-size: 3.85rem;
      font-weight: bold;
    }
    .form {
      background-color: $color-white;
      border-radius: 5px;
      height: 500px !important;
      margin-top: 6.2vh;
      margin-bottom: 1rem;
      padding-top: 45px;
      text-align: left;
      width: 524px;
      .email,
      .password {
        width: 400px;
      }
      .password {
        margin-top: 15px;
      }
      input {
        border: 2px solid $color-grey-10;
        border-radius: 5px;
        font-size: 1.14rem;
        height: 50px;
      }
      .submit {
        margin-top: 34px;
        width: 400px;
      }
      .signup {
        align-content: center;
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
        .signup-button {
          color: $color-orange;
          font-weight: bold;
          font-size: 1.1rem;
          padding-left: 5px;
        }
        .signup-button:hover {
          color: $color-hover;
        }
      }
    }
  }
}

@media (max-width: 767px){
  .login {
    width: 100%;
    margin: 0px;
    padding-top: 7vh !important;
    .login-form {
      text-align: center;
      .logo {
        width: 85%;
       }
      .title {
        color: #535353;
        font-size: 2rem;
        text-align: center;
      }
      .form {
        background-color:red;
        border-radius: 5px;
        height: 500px !important;
        margin-top: 0 !important;
        margin-bottom: 1rem;
        padding-top: 45px;
        text-align: left;
        width: 300px !important;
        .email,
        .password {
          width: 100% !important;
        }
        .password {
          margin-top: 15px;
        }
        input {
          font-size: 1rem;
          height: 50px;
        }
        .submit {
          margin-top: 34px;
          width: 100% !important;
          .btn.button-orange {
            width: 100% !important;
          }
        }
      }
    }
  }
}

//Google button
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 100%;
  height: 50px;
  background-color: $google-blue;
  border-radius: 5px;
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .btn-text {
    float: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    color: $white;
    text-decoration: none !important;
    font-size: 1.14rem;
    border-radius: 5px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
    font-weight: normal;
    height: 50px;
    align-items: center;
  }
  .btn-text:hover {
    color: $color-hover;
  }
  &:active {
    background: $button-active-blue;
  }
}

.google-link {
 text-decoration: none;
 margin-top: 10px;
}