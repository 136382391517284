@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

//white
$color-white: #ffffff;
$color-white-10: #fafafa;

//grey
$color-grey-20: #333333;
$color-grey-10: #d6d6d6;

// orange
$color-orange: #f77b2c;

// Bootstrap
$color-hover: #212529;
$color-input-border: #ced4da;
$input-border: 1px solid #ced4da;
$focused-input-border: 1px solid #86b7fe;
$focused-input-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
}
