@import 'assets/styles/variables';

.header {
  height: 100px;
  background: $color-grey-20;
  .right {
    .user-log {
      font-weight: bold;
      background-color: transparent;
      border: transparent;
      text-decoration: none;
      color: white !important;
      font-size: 1.2rem;
      .crown {
        width: 70px;
        margin-top: -10px;
        margin-right: 5px;
      }
      .user-admin { 
        background-color: $orange;
        padding: 9px;
        border-radius: 5px;
        border: 1px solid #cccccc;
      }
      .user-admin:hover { 
        color: white;
      }
    }
    .user-log:hover {
      color: orange !important;
    }
  }
  .icon {
    width: 50px;
    height: 44px;
    padding: 12px;
    color: #fafafa;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
}

@media (max-width: 767px){
  .header {
    height: 100px;
    background: $color-grey-20;
    .left { 
      width: 20%;
      img {
        width: 100px;
      }
    }
    .right { 
      width: 65%;
      padding: 0;
      .btn {
        width: 18px;
      }
      .user-log {
        font-size: 0.8rem;
        margin-left: 10px;
        .crown {
          width: 40px;
        }
      }
    }
  }  
}


