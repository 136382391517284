@import 'assets/styles/variables';

.pager {
	justify-content: center;
	margin-top: 2rem;
	ul {
		display: inline-block;
	}
}
.number {
	color: black;
	border-color: $orange;
	background-color: $orange;
}
.number:hover {
	color: black;
	border-color: rgb(228, 221, 221);
	background-color: rgb(228, 221, 221);
}
.selected {
	color: black;
	border-color: rgb(228, 221, 221);
	background-color: rgb(228, 221, 221);
}
.MuiPagination-ul  {
  margin: 1rem !important;
  justify-content: center !important;
}
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  background-color:#F77B2C !important;
  color: white !important;
}
