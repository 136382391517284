@import 'assets/styles/variables';

.personal-data-form {
  width: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
  .form-body {
    .icon {
      width: 50px;
      height: 44px;
      padding: 12px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      color: black;
    }
    .profile-picture {
      font-weight: bold;
      width: auto;
      #form-label-profile {
        margin-top: 1.2rem;
      }
      img {
        margin-top:20px;
      }
      .profile-picture-delete {
        cursor: pointer;
        float: right;
        border: none;
        input {
          display: none;
        }
      }
      .profile-picture-update {
        cursor: pointer;
        float: right;
        padding-right: 0.7rem;
        input[type='file'] {
          display: none;
        }
      }
      .img {
        display: block;
        margin: auto;
        width: 100%;
        text-align: center;
        padding-right: 0px;
        .profile-img {
          border-radius: 50%;
          border: 5px solid #fff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          height: 200px;
          width: 200px; 
        }
      }
    }
    .deleteCV {
      justify-content: center;
      display: flex;
      .profile-cv-delete {
        cursor: pointer;
        float: right;
        border: none;
        input {
          display: none;
        }
      }
    }
    .text-form {
      label {
        font-weight: bold;
      }
      input {
        width: 100%;
        height: 45px;
      }
      .first-name,
      .last-name,
      .country,
      .city,
      .email,
      .phone {
        margin: 0px;
      }
      .introduction, 
      .curriculumFIle, 
      .skills {
        margin: 0px;
        textarea {
          height: 5rem;
          max-height: 9rem;
          text-align: left;
          display: block;
        }
      }
      .skills-row {
        max-width: 800px;
        .skill-badge {
          font-size: 1rem;
          background: #dad8d8 0% 0% no-repeat padding-box;
          color: black;
        }
      }
      .curriculum-preview {
        height: 500px;
      }
      .submit {
        margin: auto;
        width: 25%;
      }
    }
    .save-submit {
      margin-right: 12px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 10rem;
        font-size: 1.2rem;
      }
      button:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
}

@media (max-width: 1100px) and (min-width: 769px) {
  .personal-data-form {
    .form-body {
      #formRight {
        width: 100%;
        padding: 10px;
      }
      .profile-picture {
        .img {
          display: block;
          margin: auto;
          .profile-img {
            height: 200px;
            width: 200px; 
            margin-top:30px;
            margin-bottom:30px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .personal-data-form {
    width: 100%;
    .form-body {
      width: 100%;
      .icon {
        margin: 0px 20px;
      }
      .iconBack {
        width: 90%;
        margin: 0px 10px;
      }
      .text-form {
        .submit {
          margin: 0px;
          width: 100% !important;
          .btn.button-orange {
            width: 100% !important;
          }
        }
      }
    }
  }
}