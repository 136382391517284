@import 'assets/styles/variables';

.language-form {
  width: auto;
  padding-right: 2rem;
  padding-left: 10rem;
  justify-content: center;
  margin: auto;
  .form-body {
    .text-form {
      label {
        font-weight: bold;
      }
      input {
        width: 100%;
        height: 45px;
      }
      .idiom, 
      .level    
      {
        width: 100%;
      }
      .button-level {
        outline: none;
        background-color: transparent;
        border: none;
        cursor: default;
       }
      .description {
        max-width: 800px;
        textarea {
          height: 5rem;
          max-height: 9rem;
          text-align: left;
          display: block;
        }
      }
    }
    .languages-row {
      max-width: 800px;
      .language-badge {
        font-size: 1rem;
      }
    }
    .save-submit {
      margin-right: 12px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      button.save {
        width: 12rem;
      }
      button.continue {
        width: auto !important;

      }
      button {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
      button:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
}
.filter {
  max-width: 800px;
  margin-top: 2rem;
}
.card-language-form {
  max-width: 800px;
  width: auto;
  padding-right: 10rem;
  padding-left: 2rem;
  justify-content: center;
  margin: auto;
  .picture-search-filter{
    color: black;
    cursor: pointer;
    margin: 5px 8px;
    img {
      height: 23px;
    }
  }
  .picture-delete-filter{
    color: black;
    cursor: pointer;
    margin: 0px 8px;
    img {
      height: 23px;
    }
  }
  .text-no-language {
    margin-top: 30px;
    font-size: 1.4rem;
    color: $orange;
    font-weight: bold;
    border-bottom: 1px solid $orange;
    padding: 10px;
  }
}
.card-language {
  width: auto;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  label {
    font-weight: bold;
    font-size: medium;
  }
  .profile-picture-delete {
    cursor: pointer;
    float: right;
    border: none;
    input {
      display: none;
    }
  }
  .profile-picture-edit {
    cursor: pointer;
    float: right;
    padding-right: 0.7rem;
    input {
      display: none;
    }
  }
  .save-submit {
    float: right;
    margin-right: 12px;
    margin-bottom: 12px;
    button {
      width: 12rem;
      font-size: 1rem;
    }
  }
  .cancel-submit {
    float: right;
    margin-right: 10px;
    margin-bottom: 10px;
    button {
      background-color: gray;
      border-color: gray;
      width: 10rem;
      font-size: 1.2rem;
      margin-right: 1rem;
    }
    button:hover {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
  .idiom, 
  .level {
    max-width: 60%;
  }
}
.card-input {
  border: 0;
}
.card-text-area {
  border: 0;
  width: 100%;
  height: 45px;
}

h5 {
  text-align: center;
}

@media (max-width: 992px) and (min-width: 768px){
  .language-form {
    padding-right: 1rem;
    padding-left: 3rem;
  }
  .card-language-form {
    padding-right: 3rem;
    padding-left: 1rem;
  }    
}

@media (max-width: 767px){
  .language-form {
    padding: 2rem;
  }
  .card-language-form {
    padding: 2rem;
    .leftFilter {
      width: 60%;
    }
    .rightFilter {
      width: 40%;
    }
    .text-no-language {
      margin-top: 20px;
    }
  }    
}