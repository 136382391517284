@import 'assets/styles/variables';

.editUser-data-form {
  width: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
  .form-body {
    width: 700px;
    
    .text-form {
      label {
        font-weight: bold;
      }
      input {
        width: 100%;
        height: 45px;
      }
      .first-name,
      .last-name,
      .country,
      .city,
      .email,
      .phone {
        margin: 0px;
        min-width: 150px;
      }
      .phone {
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
        input[type=number] { -moz-appearance:textfield; }
      }
    }

    .save-submit {
      margin-right: 12px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      button.save {
        width: 12rem;
      }

      button {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
      button:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
}

@media (max-width: 1100px) and (min-width: 769px) {
  .personal-data-form {
    .form-body {
      width: 100%;
      padding: 10px;
    }
  }
}

@media (max-width: 768px) {
  .personal-data-form {
    .form-body {
      #formRight {
        width: 100%;
        padding: 30px;
      }
    }
  }
}

.error {
  color: $danger;
  font-weight: bold;
}