@import 'assets/styles/variables';

.login {
  display: flex;
  justify-content: center;
  padding-top: 15.09vh;
  .login-form {
    text-align: center;
    .title {
      color: #535353;
      font-size: 3.85rem;
      font-weight: bold;
    }
    .form {
      background-color: $color-white;
      border-radius: 5px;
      height: 450px;
      margin-top: 6.2vh;
      margin-bottom: 1rem;
      padding-top: 45px;
      text-align: left;
      width: 524px;
      .rep-password,
      .password {
        width: 400px;
      }
      .rep-password {
        margin-top: 15px !important;
      }
      input {
        border: 2px solid $color-grey-10;
        border-radius: 5px;
        font-size: 1.14rem;
        height: 50px;
      }
      .submit {
        margin-top: 34px;
        width: 400px;
      }
      .signup {
        align-content: center;
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
        .signup-button {
          color: $color-orange;
          font-weight: bold;
          font-size: 1.1rem;
          padding-left: 5px;
        }
        .signup-button:hover {
          color: $color-hover;
        }
      }
    }
  }
}